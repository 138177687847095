@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap");
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700);

@media screen and (max-width: 590px) {
  .whatsapp,
  .twitter-icon-html,
  .ig-icon-html {
    display: block;
  }

  .whatsapp {
    position: fixed;
    z-index: 1000;
    width: 50px;
    height: 50px;
    bottom: 250px;
    right: 20px;
    /* background-color: #25d366; */
    font-size: larger;
    text-align: center;
    /* box-shadow: 15px 35px 15px black; */
    border-radius: 50px;
    border-color: #25d366;
    overflow: hidden;
  }
  .s-icon a {
    width: 100%;
    height: 100%;
  }
  .s-icon a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .twitter-icon-html {
    position: fixed;
    z-index: 1000;
    width: 50px;
    height: 50px;
    bottom: 300px;
    right: 20px;
    /* background-color: #25d366; */
    font-size: larger;
    text-align: center;
    /* box-shadow: 15px 35px 15px black; */
    border-radius: 50px;
    border-color: #25d366;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .twitter-icon-html .size,
  .ig-icon-html .size {
    font-size: 3rem;
  }
  .ig-icon-html {
    position: fixed;
    z-index: 1000;
    width: 50px;
    height: 50px;
    bottom: 350px;
    right: 20px;
    /* background-color: #25d366; */
    font-size: larger;
    text-align: center;
    /* box-shadow: 15px 35px 15px black; */
    border-radius: 50px;
    border-color: #25d366;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .whatsappShadow {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    /* background-color: #25d366; */
    font-size: larger;
    text-align: center;
    border-radius: 50px;
    z-index: 1000;
  }
}

:root {
  --white-text: rgba(255, 255, 255, 0.75);
  --main: #ff5722;
  --tick: green;
  --blue: #287bff;
  --main-darker: #ca0010;
  --main-lighter: rgba(232, 28, 46, 0.5);
  --second: #ccb686;
  --red: #e81c2e;
  --geen: #e81c2e;
  --font-main: "Ubuntu";
  --font-headers: "Barlow Semi Condensed";
  --font-subheaders: "Barlow Semi Condensed";
  --black: #19191b;
  --black-darker: #000000;
  --black-text: rgba(25, 25, 27, 1);
  --black-light: rgba(25, 25, 27, 0.7);
  --gray: #f2f2f2;
  --gray-lighter: rgba(242, 242, 242, 0.5);
  --white: #ffffff;
  --box-shadow: 0 0 15px rgb(0 0 0 / 10%);
  /* radius */
  --input-radius: 8px;
  --border-radius: 8px;
  /* Font Sizes */
  --heading-1: 2.8rem;
  --heading-2: 2.4rem;
  --heading-3: 1.8rem;
  --body-text: 1.4rem;
  --link-text: 1.4rem;
  --input-text: 1.6rem;
  --small-text: 1.4rem;
  /* Shadows */
  --shadow-1: 0px 1px 3px 0px rgba(47, 50, 125, 0.1),
    0px 1px 2px 0 rgba(47, 50, 125, 0.06);
  --shadow-2: 0px 4px 6px -1px rgba(47, 50, 125, 0.1),
    0px 2px 4px -1px rgba(47, 50, 125, 0.06);
  --shadow-3: 0px 12px 27px -3px rgba(47, 50, 125, 0.1),
    0px 4px 6px -2px rgba(4, 20, 22, 0.06);
  /* Radius */
  --card-radius: 12px;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", sans-serif;
  font-size: 1.6rem;
  line-height: 1.6;
  font-weight: 400;
  color: var(--black);
  box-sizing: border-box;
}

a {
  font-weight: 400;
  text-decoration: none;
  color: inherit;
}

p {
  font-size: 1.6rem;
}

ul,
ol {
  list-style: none;
}

.foot-contact ul li .address {
  margin-right: 1rem;
  color: green;
}

.swiper-wrapper.pms-x
  .swiper
  .swiper-wrapper
  .swiper-slide-active
  .swiper-slide
  img {
  animation-name: animateHeader;
  animation-duration: 10s;
}

.swiper-wrapper.pms-x .swiper-slide {
  width: 100%;
  height: 90vh;
}
.swiper-wrapper.pms-x .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes animateHeader {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
  }
}

section.teams {
  display: flex;
  justify-content: stretch;
  align-items: center;
  flex-direction: column;
  background-color: #151515;
  padding: 6rem 0;
  color: #fff;
}
section.teams > h1 span {
  color: #ff5722;
}
section.teams > ul {
  align-self: stretch;
  width: 100%;
  padding: 0 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 4rem;
  margin-top: 4rem;
}
section.teams > ul li .image {
  width: 100%;
  height: 30rem;
  position: relative;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}
section.teams > ul li .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
section.teams > ul li .image .social {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.74);
  top: 0;
  left: 0;
  transform: translateY(-100%);
  transition: transform 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}
section.teams > ul li .image .social > ul {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
}
section.teams > ul li .image .social > ul li {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
section.teams > ul li .image .social > ul li > * {
  font-size: 2rem;
}
section.teams > ul li .image .social > ul li:not(:last-child) {
  margin-right: 1rem;
}
section.teams > ul li .image:hover .social {
  transform: translateY(0);
}
section.teams > ul li .description {
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0;
}
section.teams > ul li .description p {
  font-size: 2rem;
  font-size: bold;
  margin-bottom: 0.2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}
section.teams > ul li .description .field {
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: grey;
}
section.teams > ul li .description span {
  color: #ff5722;
  text-transform: uppercase;
}

.about-page-header {
  width: 100%;
  background: grey;
  padding-top: 100px;
  padding-bottom: 72px;
}
.about-page-header h1 {
  position: relative;
  padding-bottom: 24px;
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}
.about-page-header h1::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 64px;
  height: 4px;
  border-radius: 2px;
  background: green;
}

.swiper-container.causes-slider .swiper-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
}

.sidenav_site-navigation {
  display: none;
}
@media screen and (max-width: 992px) {
  .sidenav_site-navigation {
    display: block;
  }
}
.sidenav_site-navigation ul {
  position: fixed;
  top: 0;
  left: -320px;
  z-index: 9999;
  width: 320px;
  max-width: calc(100% - 120px);
  height: 100%;
  padding: 30px 15px;
  margin: 0;
  background: #fff;
  list-style: none;
  overflow-x: scroll;
  transition: all 0.35s;
}
.sidenav_site-navigation ul.show {
  left: 0;
}
.sidenav_site-navigation ul li {
  margin: 0 16px;
}
.sidenav_site-navigation ul li:first-child {
  border-bottom: 3px solid green;
}
.sidenav_site-navigation ul li:first-child a {
  color: green;
}
.sidenav_site-navigation ul li a {
  display: block;
  padding: 16px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: #262626;
  text-decoration: none;
  transition: color 0.35s;
}
.sidenav_site-navigation ul li:hover a {
  color: green;
}

.entry-footer.pmx {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
}
.entry-footer.pmx a {
  font-size: 1.6rem;
}
.entry-footer.pmx a:nth-child(3),
.entry-footer.pmx a:nth-child(4),
.entry-footer.pmx a:nth-child(5) {
  color: green;
}

.entry-content ul li {
  display: flex;
  justify-content: stretch;
  align-items: center;
  margin-bottom: 10px;
}
.entry-content ul li .done {
  margin-right: 10px;
  color: green;
}

.pmxh h1 {
  background: linear-gradient(to right, green, red);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: relative;
  margin-bottom: 10px;
  padding: 10px 0;
}
.pmxh h1::after {
  content: "";
  width: 80px;
  height: 2px;
  position: absolute;
  background: #151515;
  left: 0;
  bottom: 0;
}

.pmxh ul li {
  display: flex;
  justify-content: stretch;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
}
.pmxh ul li .done {
  margin-right: 10px;
}

.swiper-fade .swiper-slide {
  pointer-events: visible !important;
}

.row.portfolio-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

body {
  color: #262626;
  font-size: 16px;
  line-height: 1.8;
  font-family: "Montserrat", sans-serif;
}

img {
  vertical-align: baseline;
}

a {
  color: #262626;
}

a:visited {
  color: #757686;
}

a:hover,
a:focus,
a:active {
  color: #262626;
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
  list-style: none;
}

.top-header-bar {
  background: green;
  color: #fff;
}

.header-bar-email,
.header-bar-text {
  padding: 20px 0;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  cursor: pointer;
}

.header-bar-text p a {
  color: #fff;
}

.header-bar-email a,
.header-bar-text span {
  text-transform: lowercase;
  color: #fff;
  text-decoration: none;
}

.header-bar-text {
  margin-left: 48px;
}

.header-bar-text p {
  margin: 0;
}

.donate-btn a {
  display: inline-block;
  padding: 20px 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  text-decoration: navajowhite;
  background: #262222;
}
.donate-btn a:hover {
  color: #fff;
  text-decoration: none;
}

.site-branding {
  width: calc(100% - 24px);
  padding: 5px 0;
}

.site-branding a {
  margin-top: 6px;
}

@media screen and (min-width: 992px) {
  .site-branding {
    width: auto;
  }
}

.hamburger-menu {
  position: relative;
  width: 24px;
  height: 22px;
  margin: 0 auto;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.hamburger-menu span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #262626;
  border-radius: 10px;
  opacity: 1;
  left: 0;
  transition: 0.25s ease-in-out;
}

.hamburger-menu span:nth-child(1) {
  top: 2px;
}

.hamburger-menu span:nth-child(2),
.hamburger-menu span:nth-child(3) {
  top: 10px;
}

.hamburger-menu span:nth-child(4) {
  top: 18px;
}

.hamburger-menu.open span:nth-child(1) {
  top: 18px;
  width: 0;
  left: 50%;
}

.hamburger-menu.open span:nth-child(2) {
  transform: rotate(45deg);
}

.hamburger-menu.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.hamburger-menu.open span:nth-child(4) {
  top: 18px;
  width: 0;
  left: 50%;
}

.site-navigation ul {
  position: fixed;
  top: 0;
  left: -320px;
  z-index: 9999;
  width: 320px;
  max-width: calc(100% - 120px);
  height: 100%;
  padding: 30px 15px;
  margin: 0;
  background: #fff;
  list-style: none;
  overflow-x: scroll;
  transition: all 0.35s;
}

.site-navigation.show ul {
  left: 0;
}

.site-navigation ul li {
  margin: 0 16px;
}

.site-navigation ul li a {
  display: block;
  padding: 16px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: #262626;
  text-decoration: none;
  transition: color 0.35s;
}

@media screen and (min-width: 992px) {
  .site-navigation ul {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    max-width: 100%;
    height: auto;
    padding: 0;
    overflow: auto;
    background: transparent;
  }
  .site-navigation ul li a {
    padding: 49px 0 30px;
  }
}

.site-navigation ul li {
  position: relative;
}
.site-navigation ul li::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 20px;
  background: green;
  transform: scale(0);
  transform-origin: right;
  transition: transform 0.5s;
}
.site-navigation ul li:hover::after,
.site-navigation ul li.current-menu-item {
  transform: scale(1);
  transform-origin: left;
}

.header-bar-cart {
  margin-left: 40px;
}

.header-bar-cart a {
  width: 48px;
  height: 100%;
}

.header-bar-cart a {
  color: #fff;
  text-decoration: none;
}

.hero-slider {
  max-height: 820px;
}

.hero-content-wrap {
  position: relative;
  color: #fff;
}

.hero-content-overlay {
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

.hero-content-wrap img {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  object-fit: cover;
}

.hero-content-overlay h1 {
  margin: 0;
  font-size: 102px;
  font-weight: 600;
  line-height: 1;
}

.hero-content-overlay h4 {
  margin: 0;
  font-size: 46px;
  font-weight: 600;
}

.hero-content-overlay p {
  font-weight: 500;
  line-height: 1.8;
}

.hero-content-overlay .entry-footer a.btn {
  color: #fff;
}

.hero-slider .pagination-wrap {
  bottom: 0;
  left: 0;
  z-index: 99;
  height: 60px;
}

.hero-slider .swiper-pagination-bullet {
  width: 24px;
  height: 20px;
  margin-right: 6px;
  border-radius: 0;
  font-size: 12px;
  color: #fff;
  background: transparent;
  opacity: 1;
}

.hero-slider .swiper-pagination-bullet-active {
  color: green;
}

.hero-slider .swiper-button-next,
.hero-slider .swiper-button-prev {
  width: 70px;
  height: 70px;
  margin-top: -35px;
  border-radius: 50%;
  background: -moz-linear-gradient(180deg, green 0%, green 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, green),
    color-stop(100%, green)
  );
  background: -webkit-linear-gradient(180deg, green 0%, green 100%);
  background: -o-linear-gradient(180deg, green 0%, green 100%);
  background: -ms-linear-gradient(180deg, green 0%, green 100%);
  background: linear-gradient(270deg, green 0%, green 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='green', endColorstr='green',GradientType=1 );
  transition: all 0.35s;
}

.hero-slider .swiper-button-next {
  right: 80px;
}

.hero-slider .swiper-button-prev {
  left: 80px;
}

.hero-slider .swiper-button-next span,
.hero-slider .swiper-button-prev span {
  display: block;
  width: 36px;
  padding-top: 15px;
  margin: 0 auto;
}

.hero-slider .swiper-button-next path,
.hero-slider .swiper-button-prev path {
  fill: #fff;
}

.hero-slider .swiper-button-next.swiper-button-disabled,
.hero-slider .swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

@media screen and (max-width: 1200px) {
  .hero-content-overlay h1 {
    font-size: 72px;
  }
  .hero-content-overlay h4 {
    font-size: 32px;
  }
  .hero-content-overlay p {
    font-size: 14px;
  }
  .hero-slider .pagination-wrap {
    height: 40px;
  }
}

@media screen and (max-width: 992px) {
  .hero-content-overlay h1 {
    font-size: 48px;
  }
  .hero-content-overlay h4 {
    font-size: 22px;
  }
  .hero-content-overlay p,
  .hero-slider .pagination-wrap,
  .hero-slider .swiper-button-next,
  .hero-slider .swiper-button-prev {
    display: none;
  }
  .hero-content-overlay footer {
    margin-top: 12px !important;
  }
}

.elements-wrap {
  margin-top: 80px;
}

.elements-page .elements-heading .entry-title {
  font-size: 24px;
  font-weight: 600;
  color: #262626;
}

.elements-page .elements-container {
  margin-top: 54px;
}

.elements-page .btn {
  margin-right: 20px;
}

.btn {
  padding: 18px 40px;
  border: 2px solid #262626;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: #262626;
  cursor: pointer;
  outline: none;
}

.btn:focus {
  outline: none;
}

.btn.orange-border {
  border-color: green;
}

.btn.orange-border:hover,
.btn.gradient-bg {
  border-color: transparent;
  background: -moz-linear-gradient(180deg, green 0%, green 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, green),
    color-stop(100%, green)
  );
  background: -webkit-linear-gradient(180deg, green 0%, green 100%);
  background: -o-linear-gradient(180deg, green 0%, green 100%);
  background: -ms-linear-gradient(180deg, green 0%, green 100%);
  background: linear-gradient(270deg, green 0%, green 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='green', endColorstr='green',GradientType=1 );
  color: #fff;
}

.accordion-wrap {
  position: relative;
}

.accordion-wrap .entry-content {
  margin: 0;
}

.accordion-wrap .entry-title {
  padding: 12px 38px;
  margin-bottom: 15px;
  border-radius: 30px;
  background: #f3f3f3;
  font-size: 14px;
  font-weight: 600;
  color: #262626;
  cursor: pointer;
}

.accordion-wrap .entry-title.active {
  border: 0;
  background: -moz-linear-gradient(180deg, green 0%, green 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, green),
    color-stop(100%, green)
  );
  background: -webkit-linear-gradient(180deg, green 0%, green 100%);
  background: -o-linear-gradient(180deg, green 0%, green 100%);
  background: -ms-linear-gradient(180deg, green 0%, green 100%);
  background: linear-gradient(270deg, green 0%, green 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='green', endColorstr='green',GradientType=1 );
  color: #fff;
}

.accordion-wrap .entry-title .arrow-r::before,
.accordion-wrap .entry-title .arrow-d::before {
  content: "+";
  font-size: 24px;
  font-weight: 400;
}

.accordion-wrap .entry-title .arrow-d::before {
  content: "-";
}

.accordion-wrap .entry-content {
  display: none;
}

.accordion-wrap .entry-content p {
  padding: 18px 38px;
  font-size: 14px;
  line-height: 2;
  color: #929191;
}

ul.tabs-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.tabs-nav .tab-nav {
  padding: 12px 32px;
  margin-right: 6px;
  border-radius: 30px;
  background: #f3f3f3;
  font-size: 14px;
  font-weight: 600;
  color: #262626;
  cursor: pointer;
  transition: all 0.35s;
}

ul.tabs-nav .tab-nav.active {
  border: 0;
  background: -moz-linear-gradient(180deg, green 0%, green 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, green),
    color-stop(100%, green)
  );
  background: -webkit-linear-gradient(180deg, green 0%, green 100%);
  background: -o-linear-gradient(180deg, green 0%, green 100%);
  background: -ms-linear-gradient(180deg, green 0%, green 100%);
  background: linear-gradient(270deg, green 0%, green 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='green', endColorstr='green',GradientType=1 );
  color: #fff;
}

.tabs .tab-nav {
  margin-bottom: -1px;
  z-index: 99;
}

.tabs-container {
  padding: 34px;
}

.tab-content {
  display: none;
}

.tab-content p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 2;
  color: #929191;
}

.circular-progress-bar {
  margin: 20px 0;
  text-align: center;
}

.circular-progress-bar .circle {
  position: relative;
  width: 156px;
  height: 156px;
  margin: 0 auto;
}

.circular-progress-bar .circle strong {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -24px;
  width: 100%;
  font-size: 48px;
  font-weight: 500;
  line-height: 1;
  color: #262626;
}

.circular-progress-bar .circle strong i {
  margin-top: 5px;
  font-size: 24px;
  font-style: normal;
}

.circular-progress-bar .entry-title {
  margin-top: 32px;
  font-size: 18px;
  font-weight: 600;
  color: #262626;
}

.counter-box {
  margin: 20px 0;
  text-align: center;
}

.counter-box .start-counter {
  margin-top: 10px;
  font-size: 48px;
  font-weight: 500;
  color: #262626;
}

.counter-box .counter-k {
  font-size: 24px;
  font-weight: 500;
}

.counter-box .entry-title {
  margin-top: 16px;
  font-size: 14px;
  color: #595858;
}

.icon-box {
  padding: 40px;
  border-radius: 24px;
  background: #ecf2f5;
  text-align: center;
  transition: all 0.35s;
}

.icon-box:hover,
.icon-box.active {
  border: 0;
  background: -moz-linear-gradient(180deg, green 0%, green 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, green),
    color-stop(100%, green)
  );
  background: -webkit-linear-gradient(180deg, green 0%, green 100%);
  background: -o-linear-gradient(180deg, green 0%, green 100%);
  background: -ms-linear-gradient(180deg, green 0%, green 100%);
  background: linear-gradient(270deg, green 0%, green 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='green', endColorstr='green',GradientType=1 );
  color: #fff;
}

.icon-box figure img:nth-of-type(1) {
  display: block;
}

.icon-box figure img:nth-of-type(2) {
  display: none;
}

.icon-box:hover figure img:nth-of-type(1),
.icon-box.active figure img:nth-of-type(1) {
  display: none;
}

.icon-box:hover figure img:nth-of-type(2),
.icon-box.active figure img:nth-of-type(2) {
  display: block;
}

.icon-box .entry-title {
  margin-top: 28px;
  font-size: 24px;
  font-weight: 600;
  color: #262626;
}

.icon-box:hover .entry-title,
.icon-box.active .entry-title {
  color: #fff;
}

.icon-box .entry-content {
  margin-top: 24px;
}

.icon-box .entry-content p {
  margin: 0;
  font-size: 14px;
  color: #595858;
}

.icon-box:hover .entry-content p,
.icon-box.active .entry-content p {
  color: #fff;
}

.barfiller {
  position: relative;
  width: 100%;
  height: 5px;
  border-radius: 3px;
  background: #d6dee1;
}

.barfiller .fill {
  display: block;
  position: relative;
  width: 0;
  height: 100%;
  border-radius: 3px;
  background: #333;
  z-index: 1;
}

.barfiller .tipWrap {
  display: none;
}

.barfiller .tip {
  left: 0;
  position: absolute;
  z-index: 2;
  width: 42px;
  height: 42px;
  padding-top: 14px;
  margin-top: -21px;
  border-radius: 50%;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  background: green;
  color: #fff;
}

.home-page-icon-boxes {
  padding: 96px 0;
}

.home-page-welcome {
  position: relative;
  padding: 96px 0;
  background-size: cover;
  z-index: 99;
}

.home-page-welcome::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(21, 21, 21, 0.9);
}

.welcome-content .entry-title {
  position: relative;
  padding-bottom: 24px;
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}

.welcome-content .entry-title::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 64px;
  height: 4px;
  border-radius: 2px;
  background: green;
}

.welcome-content .entry-content {
  font-size: 14px;
  line-height: 2;
  color: #b7b7b7;
}

.home-page-welcome img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 992px) {
  .home-page-welcome img {
    margin-bottom: 60px;
  }
}

.home-page-events {
  padding: 96px 0;
}

.section-heading .entry-title {
  position: relative;
  padding-bottom: 24px;
  font-size: 36px;
  font-weight: 600;
}

.section-heading .entry-title::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 64px;
  height: 4px;
  border-radius: 2px;
  background: green;
}

header.entry-header h2.entry-title-new {
  position: relative;
  padding-bottom: 24px;
  font-size: 36px;
  font-weight: 600;
  display: inline-block;
}
header.entry-header h2.entry-title-new::after {
  position: absolute;
  content: "";
  width: 50%;
  height: 4px;
  background: green;
  right: 0;
  bottom: 0;
}

@media screen and (max-width: 992px) {
  .home-page-events .featured-cause .section-heading {
    margin-top: 80px;
  }
}

.event-wrap {
  margin-top: 40px;
}

.event-wrap figure {
  width: 144px;
  height: auto;
}

.event-wrap figure img {
  display: block;
  width: 100%;
}

.event-content-wrap {
  width: calc(100% - 180px);
  margin-top: -10px;
}

.event-content-wrap .entry-title a {
  font-size: 18px;
  font-weight: 600;
  color: #262626;
}

.event-content-wrap .posted-date a,
.event-content-wrap .cats-links a {
  display: block;
  position: relative;
  margin-top: 6px;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  color: #262626;
}

.event-content-wrap .posted-date a {
  padding-right: 14px;
  margin-right: 14px;
}

.event-content-wrap .posted-date a::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: #262626;
}

.event-content-wrap .entry-content {
  margin-top: 12px;
  margin-bottom: 0;
  font-size: 14px;
  color: #929191;
}

.event-content-wrap .entry-footer a {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  color: green;
  text-decoration: none;
}

.home-page-events {
  padding: 96px 0;
}

.cause-wrap {
  margin-top: 50px;
}

.featured-cause .cause-wrap {
  padding: 40px 24px;
  background: #edf3f5;
}

.featured-cause .featured-cause .cause-wrap figure {
  width: 246px;
  height: auto;
}

.cause-wrap figure img {
  display: block;
  width: 100%;
}

.featured-cause .cause-content-wrap {
  width: calc(100% - 280px);
}

.cause-content-wrap .entry-title a {
  font-size: 18px;
  font-weight: 600;
  color: #262626;
}

.cause-content-wrap .posted-date a,
.cause-content-wrap .cats-links a {
  display: block;
  position: relative;
  margin-top: 6px;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  color: #262626;
}

.cause-content-wrap .posted-date a {
  padding-right: 14px;
  margin-right: 14px;
}

.cause-content-wrap .posted-date a::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: #262626;
}

.cause-content-wrap .entry-content {
  margin-top: 28px;
  margin-bottom: 0;
  font-size: 14px;
  color: #929191;
}

.cause-wrap .fund-raised {
  margin-top: 64px;
}

.fund-raised-details {
  font-size: 14px;
  color: #929191;
}

.our-causes {
  position: relative;
  padding: 96px 0;
  background-size: cover;
  z-index: 99;
}

.our-causes::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(232, 237, 238, 0.8);
}

.our-causes .cause-wrap {
  margin-top: 60px;
}

.our-causes .cause-wrap figure {
  position: relative;
}

.our-causes .cause-wrap figure .figure-overlay {
  top: 0;
  left: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.7);
  transition: all 0.5s;
}

.our-causes .cause-wrap figure:hover .figure-overlay {
  opacity: 1;
  visibility: visible;
}

.our-causes .cause-content-wrap {
  padding: 32px;
  background: #fff;
}

.our-causes .cause-content-wrap .entry-content {
  margin-top: 20px;
}

.our-causes .cause-wrap .fund-raised {
  margin-top: 48px;
}

.our-causes .swiper-button-next,
.our-causes .swiper-button-prev {
  width: 70px;
  height: 70px;
  margin-top: -10px;
  border-radius: 50%;
  background: -moz-linear-gradient(180deg, green 0%, green 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, green),
    color-stop(100%, green)
  );
  background: -webkit-linear-gradient(180deg, green 0%, green 100%);
  background: -o-linear-gradient(180deg, green 0%, green 100%);
  background: -ms-linear-gradient(180deg, green 0%, green 100%);
  background: linear-gradient(270deg, green 0%, green 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='green', endColorstr='green',GradientType=1 );
  transition: all 0.35s;
}

.our-causes .swiper-button-next {
  right: -120px;
}

.our-causes .swiper-button-prev {
  left: -120px;
}

.our-causes .swiper-button-next span,
.our-causes .swiper-button-prev span {
  display: block;
  width: 36px;
  padding-top: 15px;
  margin: 0 auto;
}

.our-causes .swiper-button-next path,
.our-causes .swiper-button-prev path {
  fill: #fff;
}

.our-causes .swiper-button-next.swiper-button-disabled,
.our-causes .swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

@media screen and (max-width: 1400px) {
  .our-causes .swiper-button-next {
    right: 0;
  }
  .our-causes .swiper-button-prev {
    left: 0;
  }
}

@media screen and (max-width: 576px) {
  .our-causes .swiper-button-next,
  .our-causes .swiper-button-prev {
    display: none;
  }
}

.home-page-limestone {
  padding: 96px 0;
}

.home-page-limestone .section-heading .entry-title {
  padding-bottom: 36px;
  line-height: 1.6;
}

.home-page-limestone .section-heading p {
  font-size: 14px;
  color: #595858;
}

.single-page .page-header {
  position: relative;
  z-index: 99;
  padding-top: 100px;
  padding-bottom: 72px;
  background-size: cover !important;
}

.single-page .page-header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.single-page .page-header h1 {
  position: relative;
  padding-bottom: 24px;
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}

.single-page .page-header h1::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 64px;
  height: 4px;
  border-radius: 2px;
  background: green;
}

.about-page .welcome-wrap {
  padding: 96px 0;
}

.about-page .welcome-content .entry-title {
  color: #262626;
}

@media screen and (max-width: 992px) {
  .about-page .welcome-content .entry-title {
    margin-top: 60px;
  }
}

.about-page .welcome-content .entry-content {
  color: #929191;
}

.about-page .welcome-wrap img {
  display: block;
  width: 100%;
}

.about-stats {
  padding: 96px 0;
  background: #262626;
}

.about-stats .circular-progress-bar .circle strong,
.about-stats .circular-progress-bar .entry-title {
  color: #fff;
}

.about-testimonial {
  padding: 0 0 96px;
}

.testimonial-cont {
  margin-top: 96px;
}

.testimonial-cont,
.testimonial-cont p {
  position: relative;
}

.testimonial-cont::before {
  content: "“";
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 48px;
  font-size: 48px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: italic;
  line-height: 1;
  color: green;
}

.testimonial-cont .entry-content {
  padding-top: 42px;
}

.testimonial-cont p {
  padding-bottom: 32px;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.6;
  font-style: italic;
}

.testimonial-cont p::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 64px;
  height: 4px;
  border-radius: 2px;
  background: green;
}

.testimonial-cont .entry-footer img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.testimonial-cont .entry-footer h4 {
  margin-left: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #595858;
}

.testimonial-cont .entry-footer h4 span {
  color: green;
}

.help-us {
  padding: 70px 0 96px;
  background: #ff3700;
}

.help-us h2 {
  margin-top: 36px;
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}

.help-us .btn {
  margin-top: 36px;
}

.help-us .btn,
.help-us .btn:hover {
  border-color: #fff;
  color: #fff;
}

.causes-page .featured-cause {
  padding: 96px 0;
}

.causes-page .our-causes {
  position: relative;
  padding: 70px 0 96px;
  background: none;
}

.causes-page .our-causes .cause-wrap {
  margin-top: 36px;
}

.causes-page .our-causes::after {
  display: none;
}

.causes-page .our-causes .cause-content-wrap {
  border: 1px solid #e0e0e0;
  border-top: 0;
}

.causes-page .highlighted-cause {
  padding: 46px 0 96px;
  background: #262626;
}

.causes-page .highlighted-cause .entry-title {
  padding-right: 24px;
  margin-top: 60px;
  line-height: 1.6;
  color: #fff;
}

.causes-page .highlighted-cause .entry-title::before {
  display: none;
}

.causes-page .highlighted-cause .entry-content {
  font-size: 14px;
  line-height: 2;
  color: #edf3f5;
}

.causes-page .highlighted-cause img {
  width: 100%;
  margin-top: 60px;
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .cause-wrap figure {
    width: 100%;
  }
  .featured-cause .cause-content-wrap {
    width: 100%;
    margin-top: 40px;
  }
}

@media screen and (max-width: 576px) {
  .cause-wrap figure {
    width: 100%;
  }
  .featured-cause .cause-content-wrap {
    width: 100%;
    margin-top: 40px;
  }
}

.single-cause .highlighted-cause {
  padding: 46px 0 96px;
}

.single-cause .highlighted-cause .entry-title {
  padding-right: 24px;
  margin-top: 60px;
  line-height: 1.6;
}

.single-cause .highlighted-cause .entry-content {
  color: #929191;
}

.single-cause .highlighted-cause img {
  width: 100%;
  margin-top: 60px;
}

.short-content-wrap {
  padding-bottom: 96px;
}

.short-content .entry-title {
  font-size: 18px;
  font-weight: 500;
}

.short-content p {
  margin-top: 40px;
  font-size: 14px;
  color: #929191;
}

.donation-form-wrap {
  padding: 96px 64px;
  margin-bottom: 96px;
  background: #edf3f5;
}

.donation-form-wrap h2 {
  font-size: 30px;
  font-weight: 500;
}

.donation-form-wrap h4 {
  font-size: 16px;
  font-weight: 500;
}

.donation-form .radio-label {
  display: block;
}

.donation-form .radio-label > input[type="radio"] {
  visibility: hidden;
  position: absolute;
}

.donation-form .radio-label > input + .donate-amount {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 40px 30px;
  margin-right: 15px;
  border: 2px solid #e0e0e0;
  font-size: 30px;
  line-height: 1;
  font-weight: 500;
  background: #fff;
}

.donation-form .radio-label:nth-last-of-type(1) > input + .donate-amount {
  margin-right: 0;
}

.donation-form .radio-label > input:checked + .donate-amount {
  border-color: green;
}

.donation-form .payment-type input[type="radio"] {
  visibility: hidden;
  position: absolute;
}

.donation-form .payment-type label {
  position: relative;
  margin-right: 40px;
  font-size: 14px;
  line-height: 1;
  color: #6a6a6a;
}

.donation-form .payment-type input[type="radio"] + .payment-type-radio {
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

.donation-form
  .payment-type
  input[type="radio"]
  + .payment-type-radio
  + .centered-dot {
  visibility: hidden;
  display: block;
  position: absolute;
  left: 7px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #ff5e06;
}

.donation-form .payment-type input[type="radio"]:checked + .payment-type-radio {
  border-color: #ff5e06;
}

.donation-form
  .payment-type
  input[type="radio"]:checked
  + .payment-type-radio
  + .centered-dot {
  visibility: visible;
}

.billing-information input {
  width: calc(50% - 14px);
  padding: 12px 30px;
  margin-top: 28px;
  border: 1px solid #e0e0e0;
  font-size: 14px;
  line-height: 1;
}

.billing-information input::placeholder {
  color: #929191;
}

@media screen and (max-width: 768px) {
  .billing-information input {
    width: 100%;
  }
}

.portfolio-wrap {
  padding: 96px 0;
}

.portfolio-wrap .mt-48 {
  margin-top: 45px;
}

.portfolio-wrap .mt-72 {
  margin-top: 72px;
}

.portfolio-wrap .portfolio-item {
  display: none;
  margin-bottom: 30px;
}

.portfolio-wrap .portfolio-item.visible {
  display: block;
}

.portfolio-cont {
  margin-bottom: 20px;
}

.portfolio-cont img {
  width: 100%;
}

.portfolio-cont .entry-title {
  margin-top: 12px;
  font-size: 18px;
  font-weight: 500;
}

.portfolio-cont .entry-title a {
  color: #262626;
}

.portfolio-cont h4 {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  color: green;
}

.news-wrap {
  padding: 96px 0;
}

.news-content {
  margin-bottom: 64px;
}

.news-content:nth-last-of-type(1) {
  margin-bottom: 0;
}

.news-content img {
  display: block;
  width: 100%;
  margin: 0;
}

.news-content .entry-header {
  margin-top: 34px;
}

.news-content .entry-header .header-elements,
.news-content .donate-icon {
  margin-top: 30px;
}

.news-content .entry-title {
  font-size: 24px;
  font-weight: 500;
}

.news-content .entry-title a {
  color: #262626;
}

.news-content .posted-date {
  font-size: 14px;
  font-weight: 500;
  color: green;
}

.post-metas {
  font-size: 12px;
  line-height: 1;
  color: #929191;
}

.post-metas a {
  color: #929191;
}

.post-metas > span {
  display: block;
  padding-right: 10px;
  margin-top: 6px;
  margin-right: 10px;
  border-right: 1px solid #929191;
}

.post-metas > span:nth-last-of-type(1) {
  border: 0;
  padding-right: 0;
  margin-right: 0;
}

.news-content .entry-content {
  margin-top: 32px;
  font-size: 14px;
  color: #929191;
}

.news-content .entry-footer {
  margin-top: 56px;
}

.pagination {
  margin-top: 92px;
}

.pagination a {
  margin-right: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  color: #262626;
  text-decoration: navajowhite;
}

.pagination a:hover,
.pagination .active a {
  color: #ff3900;
}

@media screen and (max-width: 992px) {
  .sidebar {
    margin-top: 96px;
  }
}

.sidebar h2 {
  font-size: 18px;
  font-weight: 600;
}

.search-widget input[type="search"] {
  width: calc(100% - 76px);
  height: 52px;
  padding: 10px 20px;
  border: 1px solid #e0e0e0;
  font-size: 14px;
  outline: none;
}

.search-widget input[type="search"]::placeholder {
  color: #929191;
}

.search-widget button[type="submit"] {
  width: 75px;
  height: 54px;
  border: 0;
  margin-left: -5px;
  font-size: 14px;
  font-weight: 500;
  background: green;
  color: #fff;
  cursor: pointer;
  outline: none;
}

.popular-posts {
  margin-top: 60px;
}

.popular-posts ul {
  list-style: none;
  margin: 60px 0 0;
}

.popular-posts li {
  margin-bottom: 15px;
}

.popular-posts li:nth-last-of-type(1) {
  margin-bottom: 0;
}

.popular-posts figure,
.popular-posts figure a,
.popular-posts figure img {
  display: block;
  margin: 0;
}

.popular-posts figure {
  width: 72px;
}

.popular-posts .entry-content {
  width: calc(100% - 100px);
}

.popular-posts h3 {
  font-size: 14px;
  font-weight: 500;
}

.popular-posts h3 a {
  color: #262626;
}

.popular-posts .posted-date {
  font-size: 12px;
  color: green;
}

.sidebar .upcoming-events {
  margin-top: 60px;
}

.sidebar .upcoming-events ul {
  list-style: none;
  margin: 60px 0 0;
}

.sidebar .upcoming-events li {
  margin-bottom: 32px;
}

.sidebar .upcoming-events li:nth-last-of-type(1) {
  margin-bottom: 0;
}

.sidebar .upcoming-events figure,
.sidebar .upcoming-events figure a,
.sidebar .upcoming-events figure img {
  display: block;
  margin: 0;
}

.sidebar .upcoming-events figure {
  width: 140px;
}

.sidebar .upcoming-events .entry-content {
  width: calc(100% - 140px);
}

.sidebar .upcoming-events h3 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
}

.sidebar .upcoming-events h3 a {
  color: #262626;
}

.sidebar .upcoming-events .post-metas {
  font-size: 11px;
  line-height: 1;
  color: #262626;
}

.sidebar .upcoming-events .post-metas a {
  color: #262626;
}

.sidebar .upcoming-events .post-metas > span {
  display: block;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #262626;
}

.sidebar .upcoming-events .post-metas > span:nth-last-of-type(1) {
  border: 0;
  padding-right: 0;
  margin-right: 0;
}

.sidebar .upcoming-events p {
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 14px;
  color: #929191;
}

.sidebar .featured-cause {
  margin-top: 60px;
}

.sidebar .featured-cause .cause-wrap {
  padding: 0;
  background: none;
}

.sidebar .featured-cause .featured-cause .cause-wrap figure {
  width: 100%;
}

.sidebar .cause-wrap figure img {
  display: block;
  width: 100%;
}

.sidebar .featured-cause .cause-content-wrap {
  width: 100%;
  padding: 32px;
  margin-top: 0;
  border: 1px solid #e0e0e0;
  border-top: 0;
}

.contact-page-wrap {
  padding: 0 0 96px;
}

.contact-page-wrap .entry-content {
  margin-top: 96px;
}

.contact-page-wrap .entry-content h2 {
  font-size: 24px;
  font-weight: 500;
}

.contact-page-wrap .entry-content p {
  margin-top: 20px;
  font-size: 14px;
  color: #929191;
}

.contact-page-wrap .entry-content ul.contact-social {
  padding: 20px 0 0;
  margin: 0;
  list-style: none;
}

.contact-page-wrap .entry-content ul.contact-social li {
  margin-right: 28px;
  margin-bottom: 8px;
  font-size: 14px;
}

.contact-page-wrap .entry-content ul.contact-social li a {
  color: #ff3700;
}

.contact-page-wrap .entry-content ul.contact-info {
  margin: 32px 0 0;
}

.contact-page-wrap .entry-content ul.contact-info li {
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
  font-size: 14px;
  color: #9b9a9a;
}

.contact-page-wrap .entry-content ul.contact-info .fa {
  width: 28px;
  margin-right: 10px;
  font-size: 18px;
  color: green;
  text-align: center;
}

.contact-form {
  padding: 35px 20px 55px;
  margin-top: 96px;
  background: #edf3f5;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  width: 100%;
  padding: 12px 24px;
  margin-bottom: 18px;
  border: 1px solid #e0e0e0;
  font-size: 14px;
  line-height: 1;
}

.contact-form input[type="text"]:focus,
.contact-form input[type="email"]:focus,
.contact-form textarea:focus {
  outline: none;
}

.contact-form input[type="text"]::placeholder,
.contact-form input[type="email"]::placeholder,
.contact-form textarea::placeholder {
  color: #929191;
}

.contact-form span {
  display: block;
  margin-top: 16px;
  text-align: center;
}

.contact-form input[type="submit"] {
  cursor: pointer;
}

.contact-page .contact-gmap {
  margin-top: 92px;
}

.contact-page .contact-gmap iframe {
  display: block;
  width: 100%;
  height: 440px;
}

.footer-widgets {
  position: relative;
  padding: 96px 0 90px;
  font-weight: 500;
  color: #929191;
  z-index: 99;
}

.footer-widgets::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 101%;
  /* background: rgba(22, 22, 22, 0.92); */
  background: black;
}

.footer-widgets h2 {
  margin-bottom: 40px;
  font-size: 18px;
  color: #fff;
}

.footer-widgets ul {
  padding: 12px 0 0;
  margin: 0;
  list-style: none;
}

.footer-widgets ul li {
  margin-bottom: 8px;
  font-size: 14px;
}

.footer-widgets ul li a {
  color: #a2a1a1;
  text-decoration: none;
}

.footer-widgets ul li a:hover {
  color: green;
}

.foot-about p {
  font-size: 14px;
  line-height: 2;
  color: #929191;
}

.foot-about ul li {
  margin-right: 28px;
}

.foot-about ul li a {
  color: #fff;
}

.foot-latest-news ul li {
  display: block;
  margin-bottom: 36px;
}

.foot-latest-news ul li h3 {
  font-size: 14px;
}

.foot-latest-news ul li .posted-date {
  font-size: 12px;
  color: green;
}

.foot-contact ul li {
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
}

.foot-contact ul li .fa {
  width: 28px;
  margin-right: 10px;
  font-size: 18px;
  text-align: center;
  color: green;
}

.foot-contact ul li span {
  width: calc(100% - 3px);
}

.subscribe-form {
  margin-top: 50px;
}

.subscribe-form input[type="email"] {
  width: calc(100% - 70px);
  padding: 12px 16px;
  border: 0;
  font-size: 12px;
  line-height: 1;
  background: #fff;
  color: #131212;
  outline: none;
}

.subscribe-form input[type="email"]::placeholder {
  color: #9b9a9a;
  font-style: italic;
}

.subscribe-form input[type="submit"] {
  width: 68px;
  padding: 13px 0 14px;
  border: 0;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  background: green;
  color: #fff;
  cursor: pointer;
  outline: none;
}

.footer-bar {
  padding: 30px 0;
  font-size: 12px;
  color: #7b7b7b;
  background: #131212;
  text-align: center;
}

:root {
  --green: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #868e96;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #868e96;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: "SFMono-Regular", Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  thead {
    display: table-header-group;
  }
  img,
  tr {
    page-break-inside: avoid;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
}

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul ul {
  margin-bottom: 0;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

[role="button"],
a,
area,
button,
input:not([type="range"]),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.h1,
.h2,
.h3,
.h4,
h1,
h2,
h3,
h4 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

.h1,
h1 {
  font-size: 2.5rem;
}

.h2,
h2 {
  font-size: 2rem;
}

.h3,
h3 {
  font-size: 1.75rem;
}

.h4,
h4 {
  font-size: 1.5rem;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.small,
small {
  font-size: 80%;
  font-weight: 400;
}

.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.figure {
  display: inline-block;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col,
.col-12,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-md-6,
.col-sm-4,
.col-sm-6 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

@media (min-width: 576px) {
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e9ecef;
}

.table tbody + tbody {
  border-top: 2px solid #e9ecef;
}

.table .table {
  background-color: #fff;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn:focus,
.btn:hover {
  text-decoration: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not([disabled]):not(.disabled).active,
.btn:not([disabled]):not(.disabled):active {
  background-image: none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.fade.show {
  opacity: 1;
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.tab-content > .active {
  display: block;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

button.close {
  padding: 0;
  background: 0 0;
  border: 0;
  -webkit-appearance: none;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.border {
  border: 1px solid #e9ecef !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

@media (min-width: 768px) {
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 992px) {
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
}

.visible {
  visibility: visible !important;
}

.fa {
  display: inline-block;
  font: 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-heart-o:before {
  content: "\f08a";
}

.fa-phone:before {
  content: "\f095";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-linkedin:before {
  content: "\f0e1";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-pinterest-p:before {
  content: "\f231";
}

[class*=" ti-"] {
  font-family: "themify";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev {
  left: 10px;
  right: auto;
}

.swiper-button-next {
  right: 10px;
  left: auto;
}

.swiper-button-lock {
  display: none;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 0.3s opacity;
  -o-transition: 0.3s opacity;
  transition: 0.3s opacity;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}

.swiper-pagination-lock {
  display: none;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-lock {
  display: none;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.swiper-zoom-container > canvas,
.swiper-zoom-container > img,
.swiper-zoom-container > svg {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.swiper-slide-zoomed {
  cursor: move;
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
}

@keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

a.btn {
  padding: 16px 50px;
  border-radius: 28px;
  display: inline-block;
  font-weight: 600;
}

.toggle {
  width: 7rem;
  height: 7rem;
  border-radius: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  cursor: pointer;
  overflow: hidden;
}
@media screen and (max-width: 992px) {
  .toggle {
    display: flex;
  }
}
.toggle span {
  position: absolute;
  display: inline-block;
  width: 5rem;
  height: 0.5rem;
  background-color: aquamarine;
  border-radius: 0.4rem;
}
.toggle span:nth-child(2) {
  transform: translateY(-1.2rem);
  width: 3.7rem;
  left: 1rem;
  transition: all 0.5s;
  background-color: aquamarine;
}
.toggle span:nth-child(3) {
  transform: translateY(1.2rem);
  width: 3rem;
  left: 1rem;
  transition: all 0.5s;
}
.toggle span:nth-child(1) {
  transition: all 0.5s;
}
.toggle.active span:nth-child(1) {
  transform: translateX(9rem);
}
.toggle.active span:nth-child(2) {
  width: 5rem;
  transform: translateY(0rem) rotate(45deg);
  transition-delay: 0.125s;
}
.toggle.active span:nth-child(3) {
  width: 5rem;
  transform: translateY(0rem) rotate(315deg);
  transition-delay: 0.25s;
}

.hamburger-pmx {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  width: 100%;
  position: absolute;
}

@media screen and (max-width: 992px) {
  .container-pmx {
    position: relative;
  }
}
.hide-d {
  display: none !important;
}

.social-media-wrapper {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  z-index: 10000000;
  width: 100%;
}

.social-media-wrapper a {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin: 5px;
  background-color: transparent;
  cursor: pointer;
}

.social-icon {
  height: 100% !important;
  width: 100% !important;
  border-radius: 50%;
  margin: 5px;
}

.whatsApp {
  fill: #25d366 !important;
}
@media screen and (max-width: 750px) {
  .hide-m {
    display: none !important;
  }
  .hide-d {
    display: block !important;
  }
  .row {
    margin-right: 0px;
    margin-left: 0px;
  }

  .social-media-wrapper {
    right: 2px;
    background-color: transparent;
    align-items: flex-end;
  }
  .social-media-wrapper a {
    height: 30px;
    width: 30px;
    background-color: transparent;
  }
}
.gtd {
  display: flex;
  text-align: center;
  justify-content: center;
}
#google_translate_element {
  width: 100%;
}
